import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import FormularioPrueba from "./components/FormularioPrueba";
import PDFPrueba from "./components/PDFPrueba";

import imageInici from './inicio.jpg';

function App() {

  const [inputValue, setInputValue] = useState("");

  const showSwal = () => {
    withReactContent(Swal)
      .fire({
        title: <i>Telèfon de l'usuari</i>,
        input: "text",
        inputValue,
        preConfirm: () => {
          setInputValue(Swal.getInput()?.value || "");
        },
      })
      .then((result) => {
        let inputValue = result.value;

        fetch(
          "https://fct.dawidpelc.com/test-app/public/api/resultados_pruebas/fechaUltimoTest/" +
            inputValue,
          {
            method: "GET",
          }
        )
          .then((respuesta) => {
            return respuesta.json();
          })
          .then((respuestajson) => {
            if (respuestajson.status_code == "OK") {
              sessionStorage.setItem("telefono", inputValue);
              const date = new Date(respuestajson.result);
              const formatter = new Intl.DateTimeFormat("es-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              });
              const formattedDate = formatter.format(date);
              withReactContent(Swal)
                .fire({
                  title: "Ja s'han registrat proves per aquest usuari",
                  html:
                    `
    La darrera prova registrada és de dia ` + formattedDate,
                  showDenyButton: true,
                  confirmButtonText: "Registrar nova prova",
                  denyButtonText: `Tornar enrere`,
                  icon: "info",
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    window.location.replace("/registrar");
                  } else if (result.isDenied) {
                    window.location.replace("/home");
                  }
                });
            } else {
              sessionStorage.setItem("telefonoNoExistente", inputValue);
              window.location.replace("/registrar");
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
  };

  return (
    <Router>
      <div className="App">
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand href="/home">Inici</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link onClick={showSwal}>Registrar Prova</Nav.Link>
                <Nav.Link href="/resultats">Resultats</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Switch>
          <Route path="/home">
            <div style={{ width: "100%", height: "100%", padding: "20px"}}>
              <img
                src={imageInici}
                alt="React Image"
              />
            </div>
          </Route>
          <Route path="/registrar">
            <FormularioPrueba />
          </Route>
          <Route path="/resultats">
            <h1>Resultats</h1>
          </Route>
          <Route path="/imprimir">
            <PDFPrueba />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
