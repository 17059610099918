import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import { useEffect, useState } from "react";

import logoIzq from "../images/logoizq.jpg";
import logoDer from "../images/logoderecha.png";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    fontSize: 11,
    paddingBottom: 40,
    paddingTop: 20
  },
  personalDataContainer: {
    width: "94%",
    margin: "auto",
    marginBottom: 5,
    border: 2.5,
  },
  mainHeader: {
    backgroundColor: "#999999",
    alignItems: "center",
    height: 22,
    paddingTop: 4,
    fontSize: 14,
  },
  resultatsProvesTitle: {
    backgroundColor: "#999999",
    alignItems: "center",
    height: 22,
    paddingTop: 4,
    fontSize: 12,
    borderTop: 2.5,
    borderLeft: 2.5,
    borderRight: 2.5,
  },
  testDataContainer: {
    border: 2.5,
    marginBottom: 5,
  },
  testTitle: {
    backgroundColor: "#cccccc",
    alignItems: "center",
    height: 18,
    paddingTop: 3,
  },
  sectionLine: {
    borderTop: 2,
    display: "flex",
    flexDirection: "row",
    borderColor: "#000000",
    height: 18,
  },
  leftSubsection: {
    backgroundColor: "#e6e6e6",
    width: "13%",
    alignItems: "center",
    borderRight: 2,
    paddingTop: 2,
    paddingBottom: 1,
    textAlign: "center"
  },
  rightSubsection: {
    backgroundColor: "#f2f2f2",
    width: "87%",
    alignItems: "center",
    paddingTop: 2,
    justifyContent: 'center', //Centered vertically
       flex:1
  },
  testsResultsContainer: {
    width: "94%",
    margin: "auto",
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  abajo: {
    height: "auto",
  },
  abajoDerecha: {
    padding: 2,
  },
  valoracionContainer: {
    marginTop: 0,
  },
  lastRightSubsection: {
    width: "100%",
  },
  darrerTitle: {
    backgroundColor: "#999999",
    height: 22,
    paddingTop: 4,
    fontSize: 14,
  },
  fotosContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: 50,
    paddingLeft: 20,
    paddingRight: 20,
  },
  sub1: {
    width: "70%",
  },
  sub2: {
    width: "30%",
    justifyContent: "flex-end"
  },
  logoIzq: {
    width: 180,
  },
  logoDer: {
    width: 50,
    display: "block",
    marginLeft: "auto"
  },
  sectionLineFinal: {
    border: 2.5,
    display: "flex",
    flexDirection: "row",
    borderColor: "#000000",
    marginBottom: 5
  }
});

// Create Document Component
function PDFPrueba() {
  const [datosTest, setDatosTest] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://fct.dawidpelc.com/test-app/public/api/todoData/" + sessionStorage.getItem("pk_prueba_id")
      );
      const result = await response.json();
      console.log(result);
      setDatosTest(result);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  /*const fetchData = async () => {
    await fetch("https://fct.dawidpelc.com/test-app/public/api/todoData/23", {
      method: "GET",
    })
      .then((respuesta) => {
        return respuesta.json();
      })
      .then((respuestajson) => {
        console.log(respuestajson)
      })
      .catch(function (error) {
        console.log(error);
      });
  };*/

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const getDateprintFormat = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-UK", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const formattedDate = formatter.format(date);
    return formattedDate;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        {/*render a single page*/}
        <Page size="A4" style={styles.page} wrap>
          <View style={styles.fotosContainer}>
            <View style={styles.sub1}>
              <Image style={styles.logoIzq} src={logoIzq} />
            </View>
            <View style={styles.sub1}>
              <Image style={styles.logoDer} src={logoDer} />
            </View>
          </View>
          <View style={styles.personalDataContainer} id="">
            <View style={styles.mainHeader}>
              <Text>Informació personal</Text>
            </View>
            <View style={styles.sectionLine}>
              <View style={styles.leftSubsection}>
                <Text>Usuari</Text>
              </View>
              <View style={styles.rightSubsection}>
                <Text>
                  {datosTest.datos_persona.nombre}{" "}
                  {datosTest.datos_persona.apellido}{" "}
                  {datosTest.datos_persona.segundo_apellido}
                </Text>
              </View>
            </View>
            <View style={styles.sectionLine}>
              <View style={styles.leftSubsection}>
                <Text>Gènere</Text>
              </View>
              <View style={styles.rightSubsection}>
                {datosTest.datos_persona.sexo == "H" && <Text>Home</Text>}
                {datosTest.datos_persona.sexo == "M" && <Text>Dona</Text>}
              </View>
            </View>
            <View style={styles.sectionLine}>
              <View style={styles.leftSubsection}>
                <Text>Edat</Text>
              </View>
              <View style={styles.rightSubsection}>
                <Text>{getAge(datosTest.datos_persona.fecha_nacimiento)}</Text>
              </View>
            </View>
            <View style={styles.sectionLine}>
              <View style={styles.leftSubsection}>
                <Text>Data del test</Text>
              </View>
              <View style={styles.rightSubsection}>
                <Text>{getDateprintFormat(datosTest.fecha_test)}</Text>
              </View>
            </View>
          </View>
          <View style={styles.testsResultsContainer}>
            <View style={styles.resultatsProvesTitle}>
              <Text>Resultats de les proves</Text>
            </View>
            {datosTest.resultados_tests.map((resultado_prueba) => (
              <View style={styles.testDataContainer} wrap={false}>
                <View style={styles.testTitle}>
                  <Text>{resultado_prueba.nombre_prueba}</Text>
                </View>
                <View style={styles.sectionLine}>
                  <View style={styles.leftSubsection}>
                    <Text>Resultat</Text>
                  </View>
                  <View style={styles.rightSubsection}>
                    <Text>{resultado_prueba.resultado_test}</Text>
                  </View>
                </View>
                <View style={styles.sectionLine}>
                  <View style={styles.leftSubsection}>
                    <Text>Descripció</Text>
                  </View>
                  <View
                    style={[
                      styles.rightSubsection,
                      { backgroundColor: resultado_prueba.background_color, color: resultado_prueba.text_color },
                    ]}
                  >
                    <Text>{resultado_prueba.texto_corto}</Text>
                  </View>
                </View>
                <View style={[styles.sectionLine, styles.abajo]}>
                  <View style={styles.leftSubsection}>
                    <Text>Detalls</Text>
                  </View>
                  <View style={[styles.rightSubsection, styles.abajoDerecha]}>
                    <Text>{resultado_prueba.texto_largo}</Text>
                  </View>
                </View>
              </View>
            ))}
                <View style={styles.sectionLineFinal}>
                  <View style={styles.leftSubsection}>
                    <Text>Valoració General</Text>
                  </View>
                  <View style={styles.rightSubsection}>
                    <Text>{datosTest.valoracion}</Text>
                  </View>
                </View>
                <View style={styles.sectionLineFinal}>
                  <View style={styles.leftSubsection}>
                    <Text>Nom dels Avaluadors/es</Text>
                  </View>
                  <View style={styles.rightSubsection}>
                    <Text style={{padding: "auto"}}>{datosTest.evaluadores}</Text>
                  </View>
                </View>
              </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
export default PDFPrueba;
