import { Formik } from "formik";
import "./FormularioPrueba.css";

import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import { Nav, Navbar, Container, NavDropdown, NavLink } from "react-bootstrap";

import React, { Component } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import fondo from "../images/logo.png";

const today = new Date();
const todayFormat =
  today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
const testDate = new Date().toLocaleDateString("en-CA");

export default class FormularioPrueba extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fecha_test: testDate,
      fk_telefono: "",
      nombre: "",
      apellido: "",
      segundo_apellido: "",
      evaluadores: "",
      fecha_nacimiento: "",
      sexo: "",
      altura: "",
      peso: "",
      imc: "",
      sentarse_levantarse: "",
      flexiones_brazo: "",
      flexion_tronco_silla: "",
      equilibrio: "",
      juntar_manos_espalda: "",
      levantarse_caminar_sentarse: "",
      caminar_6_min: "",
      dos_mins_marcha: "",
      valoracion: "",
    };
  }

  componentDidMount = () => {
    document.body.style.backgroundImage = "url('" + fondo + "')";
    document.body.style.backgroundSize = "cover";
    document.getElementById("fechaTest").value = testDate;
    let telefonoExistente = sessionStorage.getItem("telefono");
    if (telefonoExistente != null) {
      this.setState({
        fk_telefono: telefonoExistente,
      });
      document.getElementById("telefonoExistente").value = telefonoExistente;
      document.getElementById("telefonoExistente").disabled = true;
      this.getDatosPersonaExistente(telefonoExistente);
    }
    let telefonoNoExistente = sessionStorage.getItem("telefonoNoExistente");
    if (telefonoNoExistente != null) {
      this.setState({
        fk_telefono: telefonoNoExistente,
      });
      document.getElementById("telefonoExistente").value = telefonoNoExistente;
    }
  };

  getDatosPersonaExistente = (fk_telefono) => {
    fetch(
      "https://fct.dawidpelc.com/test-app/public/api/personas/" + fk_telefono,
      {
        method: "GET",
      }
    )
      .then((respuesta) => {
        return respuesta.json();
      })
      .then((respuestajson) => {
        this.setState({
          nombre: respuestajson.nombre,
          apellido: respuestajson.apellido,
          segundo_apellido: respuestajson.segundo_apellido,
          fecha_nacimiento: respuestajson.fecha_nacimiento,
          sexo: respuestajson.sexo,
        });
        document.getElementById("nombre").value = respuestajson.nombre;
        document.getElementById("nombre").disabled = true;
        document.getElementById("apellido").value = respuestajson.apellido;
        document.getElementById("apellido").disabled = true;
        document.getElementById("segundo_apellido").value =
          respuestajson.segundo_apellido;
        document.getElementById("segundo_apellido").disabled = true;
        document.getElementById("fecha_nacimiento").value =
          respuestajson.fecha_nacimiento;
        document.getElementById("fecha_nacimiento").disabled = true;
        document.getElementById("sexo").value = respuestajson.sexo;
        document.getElementById("sexo").disabled = true;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  onSubmit = (e) => {
    e.preventDefault();
    let stateArray = {
      fecha_nacimiento: this.state.fecha_nacimiento,
      sexo: this.state.sexo,
      imc: this.state.imc,
      sentarse_levantarse: this.state.sentarse_levantarse,
      flexiones_brazo: this.state.flexiones_brazo,
      flexion_tronco_silla: this.state.flexion_tronco_silla,
      equilibrio: this.state.equilibrio,
      juntar_manos_espalda: this.state.juntar_manos_espalda,
      levantarse_caminar_sentarse: this.state.levantarse_caminar_sentarse,
      caminar_6_min: this.state.caminar_6_min,
      dos_mins_marcha: this.state.dos_mins_marcha,
    };

    let stateArrayJSON = JSON.stringify(stateArray);

    fetch("https://fct.dawidpelc.com/test-app/public/api/previewResultados", {
      method: "POST",
      body: stateArrayJSON,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resposta) => {
        return resposta.json();
      })
      .then((respostajson) => {
        console.log(respostajson);
        let darreraProvaSwal = "";
        if (this.state.dos_mins_marcha == "") {
          darreraProvaSwal =
            "</b></p></div><div style='background-color:" +
            respostajson[7].background_color +
            "'><p style='color:" +
            respostajson[7].text_color +
            "'>Caminar 6 minuts: <b>" +
            this.state.caminar_6_min +
            "</b></p></div>";
        } else {
          darreraProvaSwal =
            "</b></p></div></br><div style='background-color:" +
            respostajson[7].background_color +
            "'><p style='color:" +
            respostajson[7].text_color +
            "'>2 minuts de marxa: <b>" +
            this.state.dos_mins_marcha +
            "</b></p></div>";
        }

        withReactContent(Swal)
          .fire({
            title: "Son correctes les dades introduïdes?",
            input: "text",
            html:
              "<div style='background-color:" +
              respostajson[0].background_color +
              "'><p style='color:" +
              respostajson[0].text_color +
              "'>IMC: <b>" +
              this.state.imc +
              "</b></p></div><div style='background-color:" +
              respostajson[1].background_color +
              "'><p style='color:" +
              respostajson[1].text_color +
              "'>Asseure's i aixecar-se d'una cadira: <b>" +
              this.state.sentarse_levantarse +
              "</b></p></div><div style='background-color:" +
              respostajson[2].background_color +
              "'><p style='color:" +
              respostajson[2].text_color +
              "'>Flexions de braç: <b>" +
              this.state.flexiones_brazo +
              "</b></p></div><div style='background-color:" +
              respostajson[5].background_color +
              "'><p style='color:" +
              respostajson[5].text_color +
              "'>Ajuntar les mans darrere de l'esquena: <b>" +
              this.state.juntar_manos_espalda +
              "</b></p></div><div style='background-color:" +
              respostajson[6].background_color +
              "'><p style='color:" +
              respostajson[6].text_color +
              "'>Aixecar-se, caminar i tornar a asseure's: <b>" +
              this.state.levantarse_caminar_sentarse +
              "</b></p></div><div style='background-color:" +
              respostajson[3].background_color +
              "'><p style='color:" +
              respostajson[3].text_color +
              "'>Flexió del tronc en cadira: <b>" +
              this.state.flexion_tronco_silla +
              "</b></p></div><div style='background-color:" +
              respostajson[4].background_color +
              "'><p style='color:" +
              respostajson[4].text_color +
              "'>Equilibri: <b>" +
              this.state.equilibrio +
              darreraProvaSwal,
            showDenyButton: true,
            confirmButtonText: "Si, desa",
            denyButtonText: `Editar les dades`,
            icon: "info",
            inputPlaceholder: "Valoració general",
            inputValidator: (value) => {
              if (!value) {
                return "Introdueix la valoració";
              }
            },
          })
          .then((result) => {
            if (result.isConfirmed) {
              let stateJSON = this.state;
              stateJSON["valoracion"] = result.value;
              let telefonoExistente = sessionStorage.getItem("telefono");
              if (telefonoExistente == null) {
                fetch(
                  "https://fct.dawidpelc.com/test-app/public/api/personas",
                  {
                    method: "POST",
                    body:
                      "pk_telefono=" +
                      this.state.fk_telefono +
                      "&nombre=" +
                      this.state.nombre +
                      "&apellido=" +
                      this.state.apellido +
                      "&segundo_apellido=" +
                      this.state.segundo_apellido +
                      "&fecha_nacimiento=" +
                      this.state.fecha_nacimiento +
                      "&sexo=" +
                      this.state.sexo,
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                    },
                  }
                )
                  .then((resposta) => {
                    return resposta.json();
                  })
                  .then((respostajson) => {})
                  .catch(function (error) {
                    console.log(error);
                  });
              }
              sessionStorage.removeItem("telefono");
              fetch(
                "https://fct.dawidpelc.com/test-app/public/api/resultados_pruebas",
                {
                  method: "POST",
                  body: JSON.stringify(stateJSON),
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
                .then((resposta) => {
                  return resposta.json();
                })
                .then((respostajson) => {
                  sessionStorage.setItem(
                    "pk_prueba_id",
                    respostajson.resultado.pk_prueba_id
                  );
                  window.location.assign("/imprimir");
                })
                .catch(function (error) {
                  console.log(error);
                });
            }
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  onChange = (e) => {
    if (e.target.name == "altura") {
      let imc = this.state.peso / Math.pow(e.target.value / 100, 2);
      imc = Math.round(imc * 100) / 100;
      document.getElementById("imc").value = imc;
      this.setState({
        imc: imc,
      });
      this.cambiarColorIMC(imc);
    }
    if (e.target.name == "peso") {
      let imc = e.target.value / Math.pow(this.state.altura / 100, 2);
      imc = Math.round(imc * 100) / 100;
      document.getElementById("imc").value = imc;
      this.setState({
        imc: imc,
      });
      this.cambiarColorIMC(imc);
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  cambiarColorIMC = (imc) => {
    let backgroundColor = "";
    if (imc < 18.49) {
      backgroundColor = "#d9d2e9";
    } else if (imc >= 18.5 && imc <= 24.99) {
      backgroundColor = "#c6efce";
    } else if (imc >= 25 && imc <= 29.99) {
      backgroundColor = "#ffeb9c";
    } else {
      backgroundColor = "#ffc7ce";
    }
    document.getElementById("imc").style.backgroundColor = backgroundColor;
  };

  onChangeRadio = (e) => {
    if (e.target.value == "caminar_6_min") {
      this.setState({
        caminar_6_min: document.getElementById("darreraProva").value,
        dos_mins_marcha: "",
      });
    } else {
      this.setState({
        caminar_6_min: "",
        dos_mins_marcha: document.getElementById("darreraProva").value,
      });
    }
  };

  onChangeDarreraProva = (e) => {
    if (this.state.caminar_6_min == "" && this.state.dos_mins_marcha == "") {
      if (document.getElementById("caminar_6_min").checked) {
        this.setState({
          caminar_6_min: e.target.value,
        });
      } else {
        this.setState({
          dos_mins_marcha: e.target.value,
        });
      }
    } else {
      if (this.state.caminar_6_min != "") {
        this.setState({
          caminar_6_min: e.target.value,
        });
      } else {
        this.setState({
          dos_mins_marcha: e.target.value,
        });
      }
    }
  };

  componentDidMount() {
    console.log(sessionStorage.getItem("registrat"));
    window.scrollTo(0, 0);
  }

  onSend = (e) => {
    window.location.assign("/login");
  };
  render() {
    return (
      <div id="wrap">
        <div className="formContainer">
          <Container
            className="containerRegistre"
            style={{ width: "100%", fontSize: 25 }}
          >
            <h1>Registrar nova prova</h1>
            <form onSubmit={this.onSubmit}>
              <h2 style={{ color: "grey" }}>Dades de l'usuari</h2>
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                    <label style={{ paddingTop: 10, paddingBottom: 10 }}>
                      Data del test
                    </label>
                    <input
                      id="fechaTest"
                      type="date"
                      className="form-control"
                      name="fecha_test"
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label style={{ paddingTop: 10, paddingBottom: 10 }}>
                      Nom
                    </label>
                    <input
                      id="nombre"
                      type="text"
                      className="form-control"
                      name="nombre"
                      onChange={this.onChange}
                      placeholder={"Nom"}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label style={{ paddingTop: 10, paddingBottom: 10 }}>
                      Primer Llinatge
                    </label>
                    <input
                      id="apellido"
                      type="text"
                      className="form-control"
                      name="apellido"
                      onChange={this.onChange}
                      placeholder={"Primer llinatge"}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label style={{ paddingTop: 10, paddingBottom: 10 }}>
                      Segon llinatge
                    </label>
                    <input
                      id="segundo_apellido"
                      type="text"
                      className="form-control"
                      name="segundo_apellido"
                      onChange={this.onChange}
                      placeholder={"Segon Llinatge"}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label style={{ paddingTop: 10, paddingBottom: 10 }}>
                      Nom dels avaluadors
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="evaluadores"
                      onChange={this.onChange}
                      placeholder={"Nom dels avaluadors"}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label style={{ paddingTop: 10, paddingBottom: 10 }}>
                      Data de naixement
                    </label>
                    <input
                      id="fecha_nacimiento"
                      type="date"
                      className="form-control"
                      name="fecha_nacimiento"
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label style={{ paddingTop: 10, paddingBottom: 10 }}>
                      Telèfon
                    </label>
                    <input
                      id="telefonoExistente"
                      type="text"
                      className="form-control"
                      name="fk_telefono"
                      onChange={this.onChange}
                      placeholder={"XXXXXXXXX"}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label style={{ paddingTop: 10, paddingBottom: 10 }}>
                      Gènere
                    </label>
                    <select
                      id="sexo"
                      name="sexo"
                      className="form-control"
                      onChange={this.onChange}
                      required
                    >
                      <option value="value1" hidden>
                        Seleccioni
                      </option>
                      <option value="H">Home</option>
                      <option value="M">Dona</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label style={{ paddingTop: 10, paddingBottom: 10 }}>
                      Altura (cm)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="altura"
                      onChange={this.onChange}
                      placeholder={"Centímetres"}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label style={{ paddingTop: 10, paddingBottom: 10 }}>
                      Pes (Kg)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="peso"
                      onChange={this.onChange}
                      placeholder={"Kilograms"}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-1">
                  <div className="form-group">
                    <label style={{ paddingTop: 10, paddingBottom: 10 }}>
                      IMC
                    </label>
                    <input
                      id="imc"
                      type="text"
                      disabled
                      className="form-control"
                      name="imc"
                      onChange={this.onChange}
                      placeholder={"IMC"}
                      required
                    />
                  </div>
                </div>
              </div>
              <h2 style={{ color: "grey", marginTop: 10 }}>
                Resultats de les proves
              </h2>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label style={{ paddingTop: 10, paddingBottom: 10 }}>
                      Asseure's i aixecar-se d'una cadira
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="sentarse_levantarse"
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label style={{ paddingTop: 10, paddingBottom: 10 }}>
                      Ajuntar les mans darrere de l'esquena
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="juntar_manos_espalda"
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label style={{ paddingTop: 10, paddingBottom: 10 }}>
                      Equilibri
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="equilibrio"
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <label style={{ paddingTop: 10, paddingBottom: 10 }}>
                      Aixecar-se, caminar i tornar a asseure's
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="levantarse_caminar_sentarse"
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label style={{ paddingTop: 10, paddingBottom: 10 }}>
                      Flexió del tronc en cadira
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="flexion_tronco_silla"
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label style={{ paddingTop: 10, paddingBottom: 10 }}>
                      Flexions de braç
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="flexiones_brazo"
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="form-group" style={{ marginTop: 10 }}>
                    <input
                      type="radio"
                      id="caminar_6_min"
                      name="test"
                      value="caminar_6_min"
                      onChange={this.onChangeRadio}
                      style={{ margin: 5 }}
                      required
                    />
                    <label for="caminar_6_min" style={{ marginRight: 20 }}>
                      {" "}
                      Caminar 6 minuts
                    </label>

                    <input
                      type="radio"
                      id="2_mins_marcha"
                      name="test"
                      value="2_mins_marcha"
                      onChange={this.onChangeRadio}
                      style={{ margin: 5 }}
                      required
                    />
                    <label for="2_mins_marcha" style={{ marginBottom: 10 }}>
                      {" "}
                      2 minuts de marxa
                    </label>
                    <input
                      id="darreraProva"
                      type="text"
                      className="form-control"
                      name="darreraprova"
                      onChange={this.onChangeDarreraProva}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="submit"
                      className="btn btn-primary botoRegistre"
                      value={"Enviar"}
                      style={{ fontSize: 25, width: "100%", marginTop: 30 }}
                      required
                    />
                  </div>
                </div>
              </div>
            </form>
          </Container>
        </div>
      </div>
    );
  }
}
